import { IS_DEV } from '~/env-client';
import * as API from '~/modules/api';

export function DreamFlareDevTools() {
  try {
    console.log('IS_DEV', IS_DEV);

    const loadTools = async () => {
      const tools = { $modules: { API }, $api: API.api };

      window.$modules = tools.$modules;
      window.$api = tools.$api;
    };

    if (IS_DEV) {
      loadTools().then(() => {
        console.log('👨‍💻 DreamFlare dev tools are ready!');
        console.log(
          'Modules loaded in $modules:',
          window.$modules
            ? Object.keys(window.$modules).join(', ')
            : 'NONE (window.$modules is not defined)',
        );
        console.log(
          `API Client is ${window.$api ? 'accessible via' : 'NOT FOUND in'} $api`,
        );
      });
    }
  } catch (error) {
    console.error('Error loading DreamFlare dev tools:', error);
  }

  return null;
}
